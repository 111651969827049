<template>
  <header class="header-about">
    <div class="wrapper">
      <div class="header-about__headline">
        <a class="headline__logo" href="/">
          <img src="/images/logo_white.svg" alt="Logo CodeTau">
        </a>
        <NavComponent></NavComponent>
      </div>
      <div class="header__banner">
        <div class="banner__info">
          <h1 class="banner__title">Команда CodeTau:</h1>
          <p class="banner__text">Мы команда профессиональных разработчиков способных превратить ваш проект в жизнь</p>
        </div>
        <div class="banner__image">
          <img src="/images/animation/ilustrations--2 1.png" alt="animation">
        </div>
      </div>
    </div>
  </header>
  <div class="howwork">
    <div class="wrapper">
      <div class="howwork__inner">
        <div class="howwork__sidebar">
          <h3>Как мы работаем?</h3>
        </div>
        <div class="howwork__main">
          <h4>Рабочий процесс:</h4>
          <div class="howwork__list">
            <div class="howwork__item">
              <div class="howwork__item_image">
                <img src="/images/howwork/group84.png" alt="">
              </div>
              <div class="howwork__item_info">
                <h5>Знакомство</h5>
                <p>Любое сотрудничество начинается со знакомства. Изучаем все детали проекта</p>
              </div>
            </div>
            <div class="howwork__item">
              <div class="howwork__item_image">
                <img src="/images/howwork/group87.png" alt="">
              </div>
              <div class="howwork__item_info">
                <h5>Техническое задание</h5>
                <p>Подготовка документа с детальным пояснением проекта.</p>
                <p><strong>Утверждение.</strong> Согласование технического задания</p>
              </div>
            </div>
            <div class="howwork__item">
              <div class="howwork__item_image">
                <img src="/images/howwork/group85.png" alt="">
              </div>
              <div class="howwork__item_info">
                <h5>Разработка</h5>
                <p>Приступаем к началу разработки</p>
                
                <h5>Онлайн</h5>
                <p>На связи с заказчиком</p>
              </div>
            </div>
            <div class="howwork__item">
              <div class="howwork__item_image">
                <img src="/images/howwork/group88.png" alt="">
              </div>
              <div class="howwork__item_info">
                <h5>Спринты</h5>
                <p>Каждые 2 недели показываем результат</p>
              </div>
            </div>
            <div class="howwork__item">
              <div class="howwork__item_image">
                <img src="/images/howwork/group86.png" alt="">
              </div>
              <div class="howwork__item_info">
                <h5>Сроки</h5>
                <p>Заканчиваем проекты в указанный срок</p>
              </div>
            </div>
            <div class="howwork__item">
              <div class="howwork__item_image">
                <img src="/images/howwork/group89.png" alt="">
              </div>
              <div class="howwork__item_info">
                <h5>Поддержка</h5>
                <p>Продолжаем поддерживать работоспособность системы</p>
              </div>
            </div>
          </div>
          <h4>Нужная от вас информация :</h4>
          <p>Полная информация о проекте</p>
          <p>Проверка и направление проекта в нужное русло</p>
          <p>По итогу сотрудничества получаете отказоустойчивый рабочий продукт.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="team">
    <div class="wrapper">
      <div class="team__inner">
        <div class="team__sidebar">
          <h3>Наша команда</h3>
        </div>
        <div class="team__main">
          <div class="team__list">
            <div class="team__item">
              <div class="team__item_image">
                <img src="/images/team/dulat.jpg" alt="">
              </div>
              <div class="team__item_info">
                <h5>Дулат</h5>
                <p>DevOps Engineer</p>
              </div>
            </div>
            <div class="team__item">
              <div class="team__item_image">
                <img src="/images/team/emil.jpg" alt="">
              </div>
              <div class="team__item_info">
                <h5>Эмиль</h5>
                <p>Product Owner</p>
              </div>
            </div>
            <div class="team__item">
              <div class="team__item_image">
                <img src="/images/team/nursultan1.jpg" alt="">
              </div>
              <div class="team__item_info">
                <h5>Нұрсұлтан</h5>
                <p>TeamLead</p>
              </div>
            </div>
            <div class="team__item">
              <div class="team__item_image">
                <img src="/images/team/zhenis.jpg" alt="">
              </div>
              <div class="team__item_info">
                <h5>Жеңіс</h5>
                <p>Senior Developer</p>
              </div>
            </div>
            <!-- <div class="team__item">
              <div class="team__item_image">
                <img src="/images/team/Yerkozha.jpg" alt="">
              </div>
              <div class="team__item_info">
                <h5>Ерқожа</h5>
                <p>Frontend Developer</p>
              </div>
            </div> -->
          </div>
          <p class="team__tagline">Мы уверены главный ресурс - люди! Мы за идею. Каждый из нас является очень важной частью команды</p>
          <div class="team__list">
            <!-- <div class="team__item">
              <div class="team__item_image">
                <img src="/images/team/asik.jpg" alt="">
              </div>
              <div class="team__item_info">
                <h5>Асылхан</h5>
                <p>Backend Developer</p>
              </div>
            </div> -->
            <div class="team__item">
              <div class="team__item_image">
                <img src="/images/team/madiyar.jpg" alt="">
              </div>
              <div class="team__item_info">
                <h5>Мадияр</h5>
                <p>Frontend Developer</p>
              </div>
            </div>
            <div class="team__item">
              <div class="team__item_image">
                <img src="/images/team/Arman.png" alt="">
              </div>
              <div class="team__item_info">
                <h5>Арман</h5>
                <p>Frontend Developer</p>
              </div>
            </div>
            <div class="team__item">
              <div class="team__item_image">
                <img src="/images/team/zhanar.jpg" alt="">
              </div>
              <div class="team__item_info">
                <h5>Жанар</h5>
                <p>UX/UI Designer</p>
              </div>
            </div>
            <!-- <div class="team__item">
              <div class="team__item_image">
                <img src="/images/team/timur.jpg" alt="">
              </div>
              <div class="team__item_info">
                <h5>Тимур</h5>
                <p>Backend Developer</p>
              </div>
            </div> -->
            <!--<div class="team__item">
              <div class="team__item_image">
                <img src="/images/team/nikolay.jpg" alt="">
              </div>
              <div class="team__item_info">
                <h5>Николай</h5>
                <p>Backend Developer</p>
              </div>
            </div> -->
            <!-- <div class="team__item">
              <div class="team__item_image">
                <img src="/images/team/asylzhan.jpg" alt="">
              </div>
              <div class="team__item_info">
                <h5>Асылжан</h5>
                <p>Backend Developer</p>
              </div>
            </div> -->
            <!-- <div class="team__item">
              <div class="team__item_image">
                <img src="/images/team/erasyl.jpg" alt="">
              </div>
              <div class="team__item_info">
                <h5>Ерасыл</h5>
                <p>Frontend Developer</p>
              </div>
            </div> -->
            <!-- <div class="team__item">
              <div class="team__item_image">
                <img src="/images/team/shynbolat.jpg" alt="">
              </div>
              <div class="team__item_info">
                <h5>Шынболат</h5>
                <p>Chief Technical Officer</p>
              </div>
            </div> -->
            <div class="team__item">
              <div class="team__item_image">
                <img src="/images/team/aigerim.jpg" alt="">
              </div>
              <div class="team__item_info">
                <h5>Айгерим</h5>
                <p>Chief Executive Officer</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="lessons">
    <div class="wrapper">
      <div class="lessons__inner">
        <div class="lessons__image">
          <img src="/images/i/code.png" alt="code">
        </div>
        <div class="lessons__text">
          <h4>Полезная Информация</h4>
          <p>Хотите научиться программировать прямо сейчас</p>
        </div>
        <div class="lessons__link">
          <a href="https://aclass.kz/school/8" target="_blank">Перейти по ссылке</a>
        </div>
      </div>
    </div>
  </div>
  <VacancyComponent></VacancyComponent>
  <FooterComponent></FooterComponent>
</template>

<script>
import NavComponent from '../components/NavComponent';
import VacancyComponent from '../components/VacancyComponent';
import FooterComponent from '../components/FooterComponent';

export default {
  name: 'About',
  components: {
    NavComponent,
    FooterComponent,
    VacancyComponent
  }
}
</script>

<style scoped>
  .header-about {
    background: linear-gradient(180deg, rgba(255, 92, 171, 0.7) 19.68%, rgba(255, 92, 171, 0.516511) 61.24%, rgba(255, 92, 171, 0.25) 100%);
    padding: 25px 0;
    color: #fff;
    min-height: 100vh;
    margin-bottom: 40px;
  }
  .header-about__headline {
    display: flex;
    justify-content: space-between;
  }
  .headline__logo {
    display: block;
  }
  /* .nav__phone {

  } */
  .header-about .header__banner {
    display: grid;
    /* padding-top: 90px; */
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    /* grid-auto-rows: minmax(200px, auto); */
  }
  .header-about .banner__info {
    padding-top: 90px;
  }
  .banner__title {
    font-size: 40px;
    margin-bottom: 1em;
  }
  .banner__text {
    font-size: 30px;
    /* font-weight: 500; */
  }

  .howwork {
    margin-bottom: 70px;
  }
  .howwork__inner {
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-gap: 20px;
  }
  
  .howwork__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
  @media screen and (max-width: 576px) {
    .lessons__image{
      text-align: center;
    }
     .header-about .header__banner {
    display: block;
    /* padding-top: 90px; */
  
    /* grid-auto-rows: minmax(200px, auto); */
  }
    .howwork__inner {
    display: grid;
    grid-template-columns: 4fr;
    grid-gap: 20px;
  }
  .howwork__list {
    display: block;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
  }
  .howwork__item {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }
  .howwork__sidebar h3 {
    font-size: 24px;
    font-weight: normal;
  }
  .howwork__main h4 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .howwork__item_info h5 {
    font-size: 18px;
  }

  .team {
    margin-bottom: 70px;
  }
  .team__inner {
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-gap: 20px;
  }
 
  .team__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 70px;
    margin-bottom: 70px;
  }
   @media screen and (max-width: 576px) {
    .team__inner {
    display: grid;
    grid-template-columns:4fr;
    grid-gap: 20px;
  }
  .howwork__main h4 {
    font-size: 30px;
    margin: 40px 0;
  }
   .team__list {
   
    margin-bottom: 0;
  }
  }
  .team__item {
    text-align: center;
  }
  .team__sidebar h3 {
    font-size: 24px;
    font-weight: normal;
  }
  .team__item_image {
    margin-bottom: 20px;
  }
  .team__item_image img {
    border-radius: 50%;
    filter: grayscale(1);
  }
  .team__item_info h5 {
    font-size: 18px;
    font-weight: normal;
  }
  .team__item_info p {
    font-weight: lighter;
  }
  .team__tagline {
    font-size: 30px;
    font-weight: lighter;
    font-style: italic;
    text-align: center;
    padding: 0 20px;
    margin-bottom: 70px;
  }

  .lessons {
    background: #FF5CAB;
  }
  .lessons__inner {
    padding: 20px 0;
    display: grid;
    grid-template-columns: 1fr 6fr 3fr;
    grid-gap: 30px;
    color: #fff;
  }
  .lessons__text, .lessons__link {
    display: flex;
    flex-flow: column;
    justify-content: center;
  }
  .lessons__text h4 {
    font-size: 18px;
    font-weight: normal;
  }
  .lessons__text p {
    font-size: 30px;
  }
  .lessons__link a {
    display: inline-block;
    text-align: center;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
    border: 1px solid #fff;
    padding: 18px 60px;
    border-radius: 10px;
    margin-left: 30px;
  }
  @media screen and (max-width: 576px) {
     .lessons__link a {
    display: inline-block;
    text-align: center;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
    border: 1px solid #fff;
    padding: 18px 60px;
    border-radius: 10px;
    margin-left: 0px;
  }
     .lessons__inner {
   
    display: block;
    
  }
  .lessons__inner img{
    max-width: 30%;
  }
  .team__list{
    grid-template-columns: 1fr 1fr;
  }
  .header-about{
    min-height: 10vh;
  }
  .header-about .banner__info{
    padding-top: 10px;
  }
  .lessons__text p{
    padding: 20px 0
  }
  }
@media screen and (min-width: 577px) and (max-width: 768px){
  .lessons__image{
      text-align: center;
    }
  .lessons__inner{
    display: flex;
    flex-direction: column;
    
  }
  .lessons__link a{
    margin-left: 0px
  }
   .lessons__inner img{
     max-width: 100px;
     }
   .header-about .header__banner {
    display: block;
  
  }
   .howwork__inner {
    display: block;
    
  }
  .team__inner{
    display: block;
  }
  .team__sidebar {
    
    margin-bottom: 20px;
  }
  .header-about{
   min-height: 20vh
  }
}

@media screen and (min-width: 769px) and (max-width: 945px) 
  {
    .header-about{
    min-height: 20vh;
  }
}

</style>
