<template>
    <router-view />
</template>

<script>

export default {
  
  name: 'App',
  // props: {
  //   msg: String
  // }
}
</script>

<style>
  @import '../public/fonts/SFPro/stylesheet.css';

  /* ------- Main ------- */
  * {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
  }
  body {
    font: 18px/1.4 'SF Pro Display';
  }
  img {
    max-width: 100%;
  }
  input, button {
    font: 18px/1.4 'SF Pro Display';
  }
  button {
    cursor: pointer;
  }

  /* ------- Helpers ------- */
  .wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  .btn-wow, .input-wow {
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    height: 40px;
    padding: 0 10px;
    align-items: center;
    /* background: #fff; */
  }
  .btn-wow {
    width: fit-content;
  }
  /* .btn-wow:hover {
    border: 1px solid #ccc;
  } */
  .btn-lux {
    border-radius: 5px;
    display: flex;
    height: 40px;
    padding: 0 10px;
    align-items: center;
    background: #fff;
  }
  .btn-lux:hover, .btn-wow:hover {
    border: 1px solid #000;
  }
  .btn-blue-wow {
    color: #fff;
    border-radius: 5px;
    /* display: flex; */
    height: 40px;
    padding: 0 10px;
    align-items: center;
    background: #1fa8ff;
  }
  .btn-blue-wow:hover {
    border: 1px solid #ccc;
    background: #fff;
  }
  h1.title {
    font-size: 24px;
  }
  h2.title {
    font-size: 20px;
    color: #333;
  }
  h1.title, h2.title {
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  .white-block {
    border-radius: 10px;
    background: #fff;
    padding: 20px;
  }
  .form-control:focus {
    border-color: #000;
    box-shadow: none;
  }
  .btn-check:focus+.btn, .btn:focus {
    box-shadow: none;
  }


</style>
