<template>
  <header class="header-home">
    <div class="wrapper">
      <div class="links">
        <a class="headline__logo" href="/">
          <img src="/images/logo_white.svg" alt="Logo CodeTau" />
        </a>
        <NavComponent></NavComponent>
      </div>
    </div>
  </header>

  <div class="wrapper">
    <div class="inner-content">
      <h1 class="headFirst">High Load Projects.</h1>
      <div class="textFirst">
        <p>
          Высоконагруженные системы (highload) стали трендом еще в 2012-ом году.
          Но до сих пор нет четкого определения данного термина. Где проходят
          границы высоких нагрузок? 10 запросов в секунду – это уже хайлоад или
          еще нет? А 100, 1000? Мы вас удивим, но дело здесь совсем не в цифрах.
        </p>
        <p>Высокая нагрузка – это сколько?</p>
        <p>
          Для начала нужно понять одну простую аксиому: высокие нагрузки –
          понятие относительное. Один веб-ресурс сможет нормально обрабатывать
          тысячу запросов в секунду, а другой обвалится на сотом коннекте. Так
          что дело тут вовсе не в количественных показателях.
        </p>

        <p>
          Мы выбрали самые популярные определения <b>Highload</b> от
          IT-специалистов :
        </p>
        <ul>
          <li>
            Хайлоад – это когда IT-система перестает справляться с текущей
            нагрузкой.
          </li>

          <li>
            Хайлоад – это когда традиционных подходов к работе IT-инфраструктуры
            уже не хватает.
          </li>

          <li>
            Хайлоад – это когда одного сервера становится мало для обслуживания
            клиентов.
          </li>

          <li>
            Хайлоад – это когда железо не справляется с выросшими нагрузками.
          </li>

          <li>
            Хайлоад – это когда возникающие проблемы нельзя решить имеющимися
            средствами.
          </li>

          <li>
            Хайлоад – это когда инфраструктуру нужно срочно масштабировать.
          </li>
        </ul>
        <p>
          По последнему определению мы понимаем, что high load - это система,
          которая постоянно масштабируется и ресурсов которой хватает для работы
          с текущими нагрузками.
        </p>

        <p>
          Таким образом, из описания состояния мы переходим к описанию качеств
          системы, которая растет вместе с потребностями бизнеса.
        </p>

        <b>5 качеств высоконагруженной системы.</b>
        <ul>
          <li>Это система с огромной аудиторией</li>
          <li>Это распределенная система</li>
          <li>Это система с позитивной динамикой</li>
          <li>Это интерактивная система</li>
          <li>Это высокоресурсная система</li>
        </ul>
        То есть, хайлоад – это система, которую нужно постоянно масштабировать.
        Настроить ее работу таким образом довольно сложно, но с точки зрения
        бизнеса оно того стоит.
      </div>
    </div>
  </div>

  <FooterComponent />
</template>

<script scoped>
import NavComponent from "../components/NavComponent";
import FooterComponent from "../components/FooterComponent";

export default {
  components: {
    NavComponent,
    FooterComponent,
  },
};
</script>

<style scoped>
.textFirst p {
  margin-bottom: 30px;
}
.textSecond p {
  margin-bottom: 30px;
}
.textFirst p {
  margin-bottom: 30px;
}
.header-home {
  background: #1a162c;
  padding: 25px 0;
  color: #fff;
  min-height: 10vh;
}
.headline__logo {
  justify-content: flex-end;
}

.headSecond {
  margin-top: 50px;
}
.textSecond {
  margin-bottom: 50px;
}

ul li {
  list-style-type: circle;
  margin-left: 30px;
}
.links {
  background: #1a162c;

  color: #fff;
  grid-template-columns: 1fr 2fr;
  display: grid;
  justify-content: center;
}

.inner-content {
  display: flex;
  flex-direction: column;
  color: black;
  margin: 50px 0;

  grid-gap: 20px;
}
.inner-content h1 {
  display: flex;
  justify-content: start;
}
.text {
  grid-area: text;
}
.content-header {
  grid-area: header;
}
</style>