<template>
  <footer class="footer">
    <div class="wrapper">
      <div class="footer__inner">
        <div class="footer__one">
          <a class="footer__logo" href="/">
            <img src="/images/logo_white.svg" alt="Logo CodeTau" />
          </a>
        </div>
        <div class="footer__two">
          <nav class="footer__nav">
            <router-link :to="{ name: 'about' }" :style="'color:' + color + ';'"
              >Кто мы</router-link
            >
            <router-link
              :to="{ name: 'services' }"
              :style="'color:' + color + ';'"
              >Услуги</router-link
            >
            <router-link :to="{ name: 'works' }" :style="'color:' + color + ';'"
              >Портфолио</router-link
            >
            <router-link
              :to="{ name: 'contacts' }"
              :style="'color:' + color + ';'"
              >Контакты</router-link
            >
          </nav>
          <div class="footer__copyright">© 2022 CodeTau</div>
        </div>
        <div class="footer__three">
          <div class="footer__socials">
            <p class="titleFirst">Как связаться с нами</p>

            <a
              class="firstIcon"
              href="https://youtube.com/channel/UCDX42MoqVOICyqL-D0eWqVw"
            >
              <img src="/images/i/youtube.png" alt="social" />
            </a>
            <a class="secondIcon" href="#">
              <img src="/images/i/linkedin.png" alt="social" />
            </a>
            <a class="thirdIcon" href="https://instagram.com/codetau">
              <img src="/images/i/instagram.png" alt="social" />
            </a>
          </div>

          <form
            action="/"
            method="POST"
            class="footer__feedback"
            @submit.prevent="sendReq"
          >
            <div
              class="form-control"
              :class="{ invalid: !form.name.valid && form.name.touched }"
            >
              <input
                name="name"
                placeholder="Ваше имя"
                v-model="form.name.value"
                @blur="form.name.blur"
                @change="onChangeInput"
              />
              <small v-if="form.name.touched && form.name.errors.required"
                >Введите имя</small
              >
            </div>
            <!-- v-model='name' -->
            <div
              class="form-control"
              :class="{ invalid: !form.name.valid && form.phone.touched }"
            >
              <input
                name="phone"
                placeholder="Номер телефона"
                type="text"
                v-model="form.phone.value"
                @blur="form.phone.blur"
              />
              <small v-if="form.phone.touched && form.phone.errors.required"
                >Введите телефон</small
              >
              <small
                v-else-if="form.phone.touched && form.phone.errors.minLength"
                >минимальное количество символов должно превышать 8 введено
                {{ form.phone.value.length }}</small
              >
            </div>
            <!--  v-model='phone' -->
            <button type="submit" :disabled="isFormActive">
              Отправить заявку
            </button>
          </form>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import instance from "../api/instance";
import { footerForm } from "../use/footerForm";
import { ref } from "vue";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

const required = (val) => !!val;
const minLength = (num) => (val) => val.length >= num;

export default {
  name: "FooterComponent",
  props: {
    color: {
      type: String,
      default: "#fff",
    },
  },
  setup() {
    let isFormActive = ref(true);
    const form = footerForm({
      name: {
        value: "",
        validators: { required },
      },
      phone: {
        value: "",
        validators: { required, minLength: minLength(8) },
      },
    });

    let success = ref("Заявка успешно отправлена");

    async function sendReq() {
      isFormActive.value = false;
      const res = await instance.post("/request/client", {
        name: form.name.value,
        phone: form.phone.value,
      });

      form.name.value = "";
      form.phone.value = "";

      createToast(res.data.message, { type: "success" });

      isFormActive.value = true;
    }

    function onChangeInput() {
      if (form.name.value.length > 2) {
        isFormActive.value = false;
      }
    }

    return { onChangeInput, isFormActive, form, sendReq, success };
  },
};
</script>

<style scoped>
.form-control.invalid input {
  border-color: #e53935;
}
.form-control input {
  color: #fff;
}
.form-control small {
  color: #e53935;
}
.footer {
  background: #1a162c;
  padding: 70px 0;
  color: #fff;
}
.footer__inner {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
}
@media screen and (max-width: 576px) {
  .footer {
    background: #1a162c;
    padding: 20px 0;
    color: #fff;
  }
  .footer__inner {
    grid-template-columns: auto;
  }
  .footer__copyright {
    text-align: center;
    text-align: left;
  }
  .footer__two {
    margin-bottom: 20px;
  }
  .footer__one {
    margin-bottom: 20px;
  }
  .footer__feedback{
    display: none;
  }
  button {
    width: 100%;
  }
}
.footer__nav {
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 576px) {
  .footer__nav {
    margin-bottom: 10px;
  }
}

.footer__nav a {
  display: inline-block;
  color: #fff;
  /* font-size: 18px; */
  /* font-weight: lighter; */
  text-decoration: none;
  margin-bottom: 30px;
}
.footer__nav a:hover {
  text-decoration: underline;
}
.footer__copyright {
  font-size: 14px;
  font-weight: lighter;
}
.footer__socials {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
}
@media screen and (max-width: 576px) {
  .foo .footer__nav {
    margin-bottom: auto;
  }
  .footer__socials {
    display: grid;
    grid-template-areas:
      "p p p"
      "f s t";
    grid-area: p;
    justify-content: stretch;
  }
  .titleFirst {
    grid-area: p;
    padding-bottom: 30px;
  }
  .firstIcon {
    grid-area: f;
  }
  .secondIcon {
    grid-area: s;
  }
  .thirdIcon {
    grid-area: t;
  }
}
.footer__socials a {
  display: block;
}
.footer__socials a:hover {
  opacity: 0.8;
}
.footer__feedback input {
  display: block;
  background: none;
  border-bottom: 1px solid #484556;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 0;
}
.footer__feedback input::placeholder {
  color: #484556;
}
.footer__feedback input ::after {
  content: "";
}
.footer__feedback input:nth-child(2) {
  margin-bottom: 50px;
}
.footer__feedback button {
  display: inline-block;
  margin-top: 50px;
  background: none;
  font-weight: bold;
  text-decoration: none;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 60px;
  border-radius: 10px;
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .footer__nav {
    margin-left: 50px;
  }
  .footer__socials {
    display: grid;
    grid-template-areas:
      "title title title"
      "first second third";
  }
  .titleFirst {
    grid-area: title;
    margin-bottom: 20px;
  }
  .firstIcon {
    grid-area: first;
  }
  .secondIcon {
    grid-area: second;
  }
  .thirdIcon {
    grid-area: third;
  }
}
@media screen and (min-width: 769px) and (max-width: 945px) {
  .footer__socials {
    display: grid;
    grid-template-areas:
      "title title title"
      "first second third";
  }
  .titleFirst {
    grid-area: title;
    margin-bottom: 20px;
  }
  .firstIcon {
    grid-area: first;
  }
  .secondIcon {
    grid-area: second;
  }
  .thirdIcon {
    grid-area: third;
  }
}
</style>
