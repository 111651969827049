<template>
  <header class="header-services">
    <div class="wrapper">
      <div class="header-services__headline">
        <a class="headline__logo" href="/">
          <img src="/images/logo_black.png" alt="Logo CodeTau" />
        </a>
        <NavComponent color="black"></NavComponent>
      </div>
      <div class="header__banner">
        <div class="banner__info">
          <h1 class="banner__title">
            Дизайн в действии,<br />
            сосредоточенный на людях
          </h1>
        </div>
        <div class="banner__image">
          <!-- <img src="/images/animation/ilustrations--2 1.png" alt="animation"> -->
        </div>
      </div>
    </div>
  </header>
  <div class="bg-header">
    <img src="/images/bg_services.jpg" alt="Services" />
  </div>
  <div class="services">
    <div class="wrapper">
      <div class="services__inner">
        <div class="services__sidebar">
          <h3>Что мы делаем?</h3>
        </div>
        <div class="services__main">
          <h4>Рабочий процесс:</h4>
          <div class="services__list">
            <div class="services__item">
              <div class="services__item_image">
                <img src="/images/services/group62.png" alt="" />
              </div>
              <div class="services__item_info">
                <h5>#Web-Разработка</h5>
                <p>Cайтов</p>
                <p>Чатботов</p>
                <p>CRM, CMS, PMS, LMS систем</p>
              </div>
            </div>
            <div class="services__item">
              <div class="services__item_image">
                <img src="/images/services/group66.png" alt="" />
              </div>
              <div class="services__item_info">
                <h5>#Mobile разработка</h5>
                <p>Кросс-платформенная</p>
                <p>Android</p>
                <p>iOS</p>
              </div>
            </div>
            <div class="services__item">
              <div class="services__item_image">
                <img src="/images/services/group63.png" alt="" />
              </div>
              <div class="services__item_info">
                <h5>#Дизайн</h5>
                <p>UI / UX Дизайн</p>
                <p>Веб дизайн</p>
                <p>Брендинг | Ребрендинг</p>
                <p>Разработка логотипа</p>
              </div>
            </div>
            <div class="services__item">
              <div class="services__item_image">
                <img src="/images/services/group67.png" alt="" />
              </div>
              <div class="services__item_info">
                <h5>#Консалтинг</h5>
                <p>Создание SRS</p>
                <p>Менеджмент проектов</p>
                <p>Консультация по строению дизайна БД</p>
                <p>Создание Road Map для проектов</p>
              </div>
            </div>
            <div class="services__item">
              <div class="services__item_image">
                <img src="/images/services/group65.png" alt="" />
              </div>
              <div class="services__item_info">
                <h5>#Маркетинг</h5>
                <p>SEO</p>
                <p>SMM</p>
                <p>Таргетинг</p>
              </div>
            </div>
            <div class="services__item">
              <div class="services__item_image">
                <img src="/images/services/group64.png" alt="" />
              </div>
              <div class="services__item_info">
                <h5>#Поддержка</h5>
                <p>Техническая поддержка</p>
                <p>Системное администрирование</p>
                <p>Хостинг</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="clients">
    <div class="wrapper">
      <div class="clients__inner">
        <swiper
          :slides-per-view="5"
          :space-between="40"
          :loop="true"
          :autoplay="{delay:5000}"
         
          navigation
          @swiper="onSwiper"
          @slideChange="onSlideChange"
    :breakpoints="swiperOptions.breakpoints"
        >
          <swiper-slide>
            <img src="/images/clients/homecredit.png" alt="cleint" />
          </swiper-slide>
          <swiper-slide>
            <img src="/images/clients/kazakhtourism.png" alt="cleint" />
          </swiper-slide>
          <swiper-slide>
            <img src="/images/clients/aqtobe.png" alt="cleint" />
          </swiper-slide>
          <swiper-slide>
            <img src="/images/clients/kolauto.png" alt="cleint" />
          </swiper-slide>
          <swiper-slide>
            <img src="/images/clients/eqonaq.png" alt="cleint" />
          </swiper-slide>
          <swiper-slide>
            <img src="/images/clients/police.png" alt="cleint" />
          </swiper-slide>
          <swiper-slide>
            <img src="/images/clients/kaz.png" alt="cleint" />
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
  <VacancyComponent></VacancyComponent>
  <FooterComponent></FooterComponent>
</template>

<script>
import NavComponent from "../components/NavComponent";
import VacancyComponent from "../components/VacancyComponent";
import FooterComponent from "../components/FooterComponent";

// Import Swiper Vue.js components
import SwiperCore, { Navigation ,Autoplay} from "swiper";
import { Swiper, SwiperSlide} from "swiper/vue";

SwiperCore.use([Navigation,Autoplay]);



import "swiper/swiper-bundle.min.css";

// new Swiper('.swiper-container', {
//   speed: 500,
//   navigation: {
//     nextEl: '.swiper-button-next',
//     prevEl: '.swiper-button-prev',
//   },
// });

// Import Swiper styles
// import 'swiper/swiper.scss';

export default {
  name: "About",
  components: {
    NavComponent,
    FooterComponent,
    VacancyComponent,
    Swiper,
    SwiperSlide,
    
  },
  data() {
    return {
      // Retourn the API Dates
      swiperOptions: {
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          770: {
            slidesPerView: 2,
            spaceBetween: 50,
          },

          771: {
            slidesPerView: 4,
            spaceBetween: 30,
          }
        },
      },
    };
  },
  methods: {
    onSwiper(swiper) {
      let prevElem = document.querySelector(".clients .swiper-button-prev");
      document.querySelector(".clients__inner").appendChild(prevElem);
      let nextElem = document.querySelector(".clients .swiper-button-next");
      document.querySelector(".clients__inner").appendChild(nextElem);
      console.log(swiper);
    },
    onSlideChange() {
      // console.log('slide change');
    },
    
  },
};
</script>

<style scoped>
.header-services {
  padding: 25px 0;
  color: #000;
  /* margin-bottom: 40px; */
}
.header-services__headline {
  display: flex;
  justify-content: space-between;
}
.headline__logo {
  display: block;
  max-width: 125px;
}
.header__banner {
  display: grid;
  padding: 50px 0;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  /* grid-auto-rows: minmax(200px, auto); */
}
.banner__title {
  font-size: 40px;
  margin-bottom: 1em;
}
@media screen and (max-width: 576px) {
  .banner__title {
    font-size: 30px;
    margin-bottom: 1em;
    text-align: center;
  }
  .header__banner {
    display: grid;
    padding: 10px 0;
    grid-template-columns: auto;
    grid-gap: unset;
    /* grid-auto-rows: minmax(200px, auto); */
  }
}
.bg-header {
  margin-bottom: 70px;
}

.services {
  margin-bottom: 70px;
}
.services__inner {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 20px;
}


.services__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.services__item {
  display: grid;
  grid-template-columns: 1fr 2fr;
}
.services__sidebar h3 {
  font-size: 24px;
  font-weight: normal;
}
.services__main h4 {
  font-size: 24px;
  margin-bottom: 40px;
}
.services__item_info h5 {
  font-size: 18px;
  margin-bottom: 0.5em;
}
.services__item_info p {
  font-weight: lighter;
}

.clients {
  background: #caf1f8;
}
.clients__inner {
  position: relative;
  padding: 20px 0;
}
@media screen and (max-width: 576px) {
  .services__inner {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 20px;
  }
  .services__list {
  display: block;
 
}
}
</style>
<style>
.clients .swiper-wrapper {
  align-items: center;
}
.clients .swiper-slide {
  text-align: center;
}
.clients .swiper-slide img {
  max-height: 95px;
}
.clients .swiper-button-next,
.clients .swiper-container-rtl .swiper-button-prev,
.clients .swiper-button-prev,
.clients .swiper-container-rtl .swiper-button-next {
  /* top: calc(50%); */
  outline: none;
}
.clients .swiper-button-prev:after,
.clients .swiper-container-rtl .swiper-button-next:after,
.clients .swiper-button-next:after,
.clients .swiper-container-rtl .swiper-button-prev:after {
  color: #4ccfe7;
  font-size: 16px;
}
.clients .swiper-button-next,
.clients .swiper-button-prev {
  width: 40px;
  height: 40px;
  background: #ffffff80;
  border-radius: 50%;
}
.clients .swiper-container-rtl .swiper-button-next:after,
.clients .swiper-button-next:after {
  margin-left: 3px;
}
.clients .swiper-button-prev:after,
.clients .swiper-container-rtl .swiper-button-prev:after {
  margin-right: 3px;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 50px;
  right: auto;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 50px;
  left: auto;
}
@media screen and (max-width: 576px) {
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    right: -10px;
    left: auto;
  }
  .swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: -10px;
  right: auto;
}
}
@media screen and (min-width: 577px) and (max-width: 768px) {
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 0px;
  right: auto;
}
  .header__banner {
  display: grid;
  padding: 0px 0;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  /* grid-auto-rows: minmax(200px, auto); */
}
  .services__inner{
    display: flex !important;
    flex-direction: column;
  }
  .swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 1px;
  left: auto;
}
}
@media screen and (min-width: 769px) and (max-width: 945px) {
  .swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 0px;
  right: auto;
}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev{
  right: 0px;
}
}
</style>