<template>
  <header class="header-contacts">
    <div class="wrapper">
      <div class="header-contacts__headline">
        <a class="headline__logo" href="/">
          <img src="/images/logo_black.png" alt="Logo CodeTau" />
        </a>
        <NavComponent color="black"></NavComponent>
      </div>
      <div class="header__banner">
        <div class="banner__info">
          <h1 class="banner__title">Давайте знакомиться</h1>
        </div>
        <div class="banner__image">
          <!-- <img src="/images/animation/ilustrations--2 1.png" alt="animation"> -->
        </div>
      </div>
    </div>
  </header>
  <div class="contacts">
    <div class="wrapper">
      <div class="contacts__inner">
        <div class="contacts__sidebar">
          <h3>Как связаться с нами</h3>
        </div>
        <div class="contacts__main">
          <form
            action="/"
            method="GET"
            class="contacts__feedback"
            @submit.prevent="sendReq"
          >
            <div
              class="form-control"
              :class="{ invalid: !form.name.valid && form.name.touched }"
            >
              <input
                type="text"
                name="name"
                placeholder="Ваше имя"
                v-model="form.name.value"
                @blur="form.name.blur"
                @change="onChange"
              />
              <small v-if="form.name.touched && form.name.errors.required"
                >Введите имя</small
              >
            </div>
            <div
              class="form-control"
              :class="{ invalid: !form.name.valid && form.phone.touched }"
            >
              <input
                type="text"
                name="phone"
                placeholder="Номер телефона"
                v-model="form.phone.value"
                @blur="form.phone.blur"
              />
              <small v-if="form.phone.touched && form.phone.errors.required"
                >Введите телефон</small
              >
              <small
                v-else-if="form.phone.touched && form.phone.errors.minLength"
                >минимальное количество символов должно превышать 8 введено
                {{ form.phone.value.length }}</small
              >
            </div>
            <button type="submit" :disabled="isFormActive">
              Отправить заявку
            </button>
          </form>
        </div>
        <div class="contacts__sidebar">
          <h3>Наш офис</h3>
        </div>
        <div class="contacts__main">
          <div class="contacts__location">
            <div class="location__text">
              <p>
                Республика Казахстан<br />
                г. Алматы<br />
                <br />
                Бостандыкский район<br />
                Байзакова, 280<br />
                Smart Point<br />
                SP3-34
              </p>
            </div>
            <div class="location__map"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <VacancyComponent></VacancyComponent>
  <FooterComponent></FooterComponent>
</template>

<script>
import NavComponent from "../components/NavComponent";
import VacancyComponent from "../components/VacancyComponent";
import FooterComponent from "../components/FooterComponent";
import instance from "../api/instance";
import { footerForm } from "../use/footerForm";
import { ref } from "vue";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
const required = (val) => !!val;
const minLength = (num) => (val) => val.length >= num;

export default {
  name: "About",
  components: {
    NavComponent,
    FooterComponent,
    VacancyComponent,
  },
  setup() {
    let isFormActive = ref(false);
    const form = footerForm({
      name: {
        value: "",
        validators: { required },
      },
      phone: {
        value: "",
        validators: { required, minLength: minLength(8) },
      },
    });

    async function sendReq() {
      const res = await instance.post("/request/client", {
        name: form.name.value,
        phone: form.phone.value,
      });
      createToast(res.data.message, { type: "success" });
      isFormActive.value = true;
    }
    function onChange() {
      if (form.name.value.length > 2) {
        isFormActive.value = false;
      }
    }
    return { form, sendReq, onChange, isFormActive };
  },
  mounted: function () {
    let script = document.createElement("script");
    script.src =
      "https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3Af5d6eec10d7065b38c428ca31eee63306d23fb198f69f86aec2c5771b51970d1&amp;width=100%25&amp;height=262&amp;lang=ru_RU&amp;scroll=true";
    document.querySelector(".location__map").appendChild(script);
  },
};
</script>

<style scoped>
.form-control.invalid input {
  border-color: #e53935;
}
.form-control input {
  color: black;
}

.form-control small {
  color: #e53935;
}

.header-contacts {
  padding: 25px 0;
  color: #000;
  /* margin-bottom: 40px; */
}
.header-contacts__headline {
  display: flex;
  justify-content: space-between;
}
.headline__logo {
  display: block;
  max-width: 125px;
}
.header__banner {
  display: grid;
  padding: 50px 0;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  /* grid-auto-rows: minmax(200px, auto); */
}
.banner__title {
  font-size: 40px;
  margin-bottom: 1em;
}
.bg-header {
  margin-bottom: 70px;
}

.contacts {
  margin-bottom: 70px;
}
.contacts__inner {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 50px;
}
.contacts__sidebar h3 {
  font-size: 24px;
  font-weight: normal;
}

.contacts__feedback {
  margin-bottom: 70px;
}
.contacts__feedback input {
  display: block;
  background: none;
  border-bottom: 1px solid #e5e5e5;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 0;

  /* margin-bottom: 30px; */
}
.location__map {
  overflow: hidden;
  border-radius: 15px;
}
.contacts__feedback input[type="text"]::placeholder {
  color: #e5e5e5;
}
.contacts__feedback input[type="text"]:nth-child(2) {
  margin-bottom: 50px;
}
.contacts__feedback button {
  display: inline-block;
  margin-top: 40px;
  background: none;
  font-weight: bold;
  text-decoration: none;
  color: #fff;
  background: #3d59ea;
  padding: 18px 60px;
  border-radius: 10px;
}

.contacts__location {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 20px;
}
.location__text {
  background: #ff8862 url("/images/i/location.png") right -20px bottom -40px
    no-repeat;
  background-size: 200px;
  color: #fff;
  padding: 30px;
  border-radius: 10px;
}
@media screen and (max-width: 576px) {
  .header__banner {
    display: block;
  }
  .header__banner {
    padding: 25px 0;
  }
  .contacts__inner {
    display: block;
  }
  .contacts__location {
    display: block;
  }
  .contacts__feedback {
    margin-bottom: 30px;
  }
  .contacts__feedback button {
    width: 100%;
  }
  .location__text {
    padding: 25px 0;
  }
  .location__text {
    background: #ff8862 url("/images/i/location.png") right -30px bottom 2px no-repeat;
    background-size: 200px;
    color: #fff;
    padding: 30px;
    border-radius: 10px;
    margin: 20px 0;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .contacts__sidebar h3 {
    margin-bottom: 20px;
  }
  .contacts__feedback button {
    padding: 18px 60px;
  }
  .header__banner {
    display: flex;
    justify-content: center;
  }
  .contacts__inner {
    display: block;
  }

  .contacts__location {
    display: block;
  }
  .location__map {
    margin-top: 20px;
  }
}
@media screen and (min-width: 769px) and (max-width: 1000px) {
  .header__banner {
    display: flex;
    justify-content: center;
  }
  .contacts__inner {
    display: block;
  }
  .contacts__location {
    display: block;
  }
  .location__map {
    margin-top: 30px;
  }
  .contacts__feedback button {
    padding: 18px 60px;
  }
}
</style>