<template>
  <header class="header-home">
    <div class="wrapper">
      <div class="links">
        <a class="headline__logo" href="/">
          <img src="/images/logo_white.svg" alt="Logo CodeTau" />
        </a>
        <NavComponent></NavComponent>
      </div>
    </div>
  </header>

  <div class="wrapper">
    <div class="inner-content">
      <h1 class="headSecond">Big Data.</h1>

      <div class="textSecond">
        <p>
          Большие данные (англ. big data, [ˈbɪɡ ˈdeɪtə]) — обозначение
          структурированных и неструктурированных данных огромных объёмов и
          значительного многообразия, эффективно обрабатываемых горизонтально
          масштабируемыми программными инструментами, появившимися в конце
          2000-х годов и альтернативных традиционным системам управления базами
          данных и решениям класса Business Intelligence.
        </p>

        <p>
          В широком смысле о «больших данных» говорят как о
          социально-экономическом феномене, связанном с появлением
          технологических возможностей анализировать огромные массивы данных, в
          некоторых проблемных областях — весь мировой объём данных, и
          вытекающих из этого трансформационных последствий.
        </p>

        <p>
          В качестве определяющих характеристик для больших данных традиционно
          выделяют «три V»: объём (англ. volume, в смысле величины физического
          объёма), скорость (velocity в смыслах как скорости прироста, так и
          необходимости высокоскоростной обработки и получения результатов),
          многообразие (variety, в смысле возможности одновременной обработки
          различных типов структурированных и полуструктурированных данных); в
          дальнейшем возникли различные вариации и интерпретации этого
          признака[⇨].
        </p>

        <p>
          С точки зрения информационных технологий, в совокупность подходов и
          инструментов изначально включались средства массово-параллельной
          обработки неопределённо структурированных данных, прежде всего,
          системами управления базами данных категории NoSQL, алгоритмами
          MapReduce и реализующими их программными каркасами и библиотеками
          проекта Hadoop. В дальнейшем к серии технологий больших данных стали
          относить разнообразные информационно-технологические решения, в той
          или иной степени обеспечивающие сходные по характеристикам возможности
          по обработке сверхбольших массивов данных.
        </p>
      </div>
    </div>
  </div>

  <FooterComponent />
</template>

<script scoped>
import NavComponent from "../components/NavComponent";
import FooterComponent from "../components/FooterComponent";

export default {
  components: {
    NavComponent,
    FooterComponent,
  },
};
</script>

<style scoped>
.textFirst p {
  margin-bottom: 30px;
}
.textSecond p {
  margin-bottom: 30px;
}
.textFirst p {
  margin-bottom: 30px;
}
.header-home {
  background: #1a162c;
  padding: 25px 0;
  color: #fff;
  min-height: 10vh;
}
.headline__logo {
  justify-content: flex-end;
}

.headSecond {
  margin-top: 50px;
}
.textSecond {
  margin-bottom: 50px;
}

ul li {
  list-style-type: circle;
  margin-left: 30px;
}
.links {
  background: #1a162c;

  color: #fff;
  grid-template-columns: 1fr 2fr;
  display: grid;
  justify-content: center;
}
.content {
  margin: 10px 150px;
  background: white;
}
.inner-content {
  display: flex;
  flex-direction: column;
  color: black;

  grid-gap: 20px;
}
.inner-content h1 {
  display: flex;
  justify-content: start;
}
.text {
  grid-area: text;
}
.content-header {
  grid-area: header;
}
</style>