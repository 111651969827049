<template>
    404 Not Found
</template>

<script>
export default {
  name: 'About',
  // props: {
  //   msg: String
  // }
}
</script>

<style>

</style>
