<template>
  <header class="header-works">
    <div class="wrapper">
      <div class="header-works__headline">
        <a class="headline__logo" href="/">
          <img src="/images/logo_black.png" alt="Logo CodeTau" />
        </a>
        <NavComponent color="#000"></NavComponent>
      </div>
      <div class="header__banner">
        <div class="banner__info">
          <h1 class="banner__title">
            #Проект или #Непроект<br />вот в чем вопрос?
          </h1>
          <p class="banner__text">
            В этом разделе размещены выполненные нами проекты. Более подробную
            информацию вы получите скачав наше портфолио
          </p>
        </div>
        <div class="banner__image">
          <img src="/images/animation/works.png" alt="animation" />
        </div>
      </div>
    </div>
  </header>
  <div class="wrapper">
    <div class="works">
      <div class="works__item">
        <img src="/images/works/eqonaq.png" alt="works" />
        <a href="https://eqonaq.kz/" target="_blank"><h5>eQonaq</h5></a>
      </div>
      <div class="works__item">
        <img src="/images/works/tourstat.png" alt="works" />
        <a href="https://tourstat.kz/" target="_blank"><h5>Tourstat</h5></a>
      </div>
      <div class="works__item">
        <img src="/images/works/smartsafety.png" alt="works" />
        <a href="https://smartsafety.kz/" target="_blank"><h5>SmartSafety</h5></a>
      </div>
      <div class="works__item">
        <img src="/images/works/aclass.png" alt="works" />
        <a href="https://aclass.kz/" target="_blank"><h5>A Class Online</h5></a>
      </div>
      <div class="works__item">
        <img src="/images/works/yote.png" alt="works" />
        <a href="https://yote.kz/" target="_blank"><h5>Yote</h5></a>
      </div>
      <div class="works__item">
        <img src="/images/works/altynadamagency.png" alt="works" />
        <a href="https://altynadamagency.com/" target="_blank"><h5>Altyn Adam Agency</h5></a>
      </div>
      <div class="works__item">
        <img src="/images/works/viewproject.png" alt="works" />
        <a href="https://project.qaztourism.kz/" target="_blank"><h5>View Project</h5></a>
      </div>
      <div class="works__item">
        <a href="#"><h5>В разработке</h5></a>
      </div>
    </div>
  </div>
  <div class="about">
    <div class="wrapper">
      <div class="about__inner">
        <div class="about__image">
          <img src="/images/i/works.png" alt="code" />
        </div>
        <div class="about__text">
          <p>Хотите узнать больше информации о нас?</p>
        </div>
        <div class="about__link">
          <a href="/files/portfolio.pdf">Скачать портфолио</a>
        </div>
      </div>
    </div>
  </div>
  <VacancyComponent></VacancyComponent>
  <FooterComponent></FooterComponent>
</template>

<script>
import NavComponent from "../components/NavComponent";
import VacancyComponent from "../components/VacancyComponent";
import FooterComponent from "../components/FooterComponent";

export default {
  name: "Works",
  components: {
    NavComponent,
    FooterComponent,
    VacancyComponent,
  },
};
</script>

<style scoped>
.header-works {
  padding: 25px 0;
  color: #000;
  margin-bottom: 140px;
}
.header-works__headline {
  display: flex;
  justify-content: space-between;
}
.headline__logo {
  display: block;
  max-width: 125px;
}
/* .nav__phone {

  } */
.header-works .header__banner {
  display: grid;
  /* padding-top: 90px; */
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  /* grid-auto-rows: minmax(200px, auto); */
}
.header-works .banner__info {
  padding-top: 90px;
}
.banner__title {
  font-size: 40px;
  margin-bottom: 1em;
}
@media screen and (max-width: 576px) {
  .about__image {
    display: flex;
    justify-content: center;
  }
  .about__inner {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }
  .banner__title {
    font-size: 35px;
    margin-bottom: auto;
    text-align: center;
  }
  .header-works .banner__info {
    padding-top: 30px;
  }
  .header-works {
    padding: 25px 0;
    color: #000;
    margin-bottom: 10px;
  }
  .header-works .header__banner {
    display: grid;
    /* padding-top: 90px; */
    grid-template-columns: auto;
    grid-gap: 25px;
    text-align: center;
    /* grid-auto-rows: minmax(200px, auto); */
  }
}
.banner__text {
  font-size: 30px;
  /* font-weight: 500; */
}

.works {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-bottom: 70px;
}
.works__item {
  display: table;
  position: relative;
  cursor: pointer;
}
.works__item h5 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: #1a162c80;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: #fff;
  font-size: 50px;
  font-weight: 900;
  opacity: 1;
  transition: 0.3s linear opacity;
}

.works__item:hover h5 {
  opacity: 0;
}

.about {
  background: #5ae8cc;
}
.about__inner {
  padding: 20px 0;
  display: grid;
  grid-template-columns: 1fr 6fr 3fr;
  grid-gap: 30px;
  color: #fff;
}
.about__text,
.about__link {
  display: flex;
  flex-flow: column;
  justify-content: center;
}
.about__text h4 {
  font-size: 18px;
  font-weight: normal;
}
.about__text p {
  font-size: 30px;
}
.about__link a {
  display: inline-block;
  text-align: center;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  border: 1px solid #fff;
  padding: 18px 60px;
  border-radius: 10px;
  margin-left: 30px;
}
@media screen and (max-width: 576px) {
  .banner__text {
    font-size: 23px;
    /* font-weight: 500; */
  }
  .works__item h5 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: #1a162c80;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: #fff;
    font-size: 20px;
    font-weight: 900;
    opacity: 1;
    transition: 0.3s linear opacity;
  }
  .banner__image img {
    max-height: 75%;
  }
  .about__inner {
    display: block;
  }
  .about__inner img {
    max-width: 30%;
  }
  .about__link a {
    display: inline-block;
    text-align: center;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
    border: 1px solid #fff;
    padding: 18px 60px;
    border-radius: 10px;
    margin-left: 0px;
    margin-top: 10px;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .banner__text {
    margin-bottom: 30px;
  }

  .header-works .header__banner {
    display: block;
  }
  .works__item h5 {
    font-size: 30px;
  }
  .banner__image img {
    max-width: 75%;
  }
  .banner__image {
    grid-template-columns: 3fr 1fr;
  }
}
</style>
