<template>
  <header class="header-home">
    <div class="wrapper">
      <div class="links">
        <a class="headline__logo" href="/">
          <img src="/images/logo_white.svg" alt="Logo CodeTau" />
        </a>
        <NavComponent></NavComponent>
      </div>
    </div>
  </header>

  <div class="wrapper">
    <div class="inner-content">
      <h1 class="headSecond">Интеграция.</h1>

      <div class="textSecond">
        <p>
          <strong>Интеграция данных</strong> включает объединение данных, находящихся в различных источниках, и предоставление данных пользователям в унифицированном виде. Этот процесс становится существенным как в коммерческих задачах (когда двум похожим компаниям необходимо объединить их базы данных), так и в научных (комбинирование результатов исследования из различных биоинформационных репозиториев, для примера). Роль интеграции данных возрастает, когда увеличивается объём и необходимость совместного использования данных. </p>
      </div>
    </div>
  </div>

  <FooterComponent />
</template>

<script scoped>
import NavComponent from "../components/NavComponent";
import FooterComponent from "../components/FooterComponent";

export default {
  name: "Integration",
  components: {
    NavComponent,
    FooterComponent,
  },
};
</script>

<style scoped>
.textFirst p {
  margin-bottom: 30px;
}
.textSecond p {
  margin-bottom: 30px;
}
.textFirst p {
  margin-bottom: 30px;
}
.header-home {
  background: #1a162c;
  padding: 25px 0;
  color: #fff;
  min-height: 10vh;
}
.headline__logo {
  justify-content: flex-end;
}

.headSecond {
  margin-top: 50px;
}
.textSecond {
  margin-bottom: 50px;
}

ul li {
  list-style-type: circle;
  margin-left: 30px;
}
.links {
  background: #1a162c;

  color: #fff;
  grid-template-columns: 1fr 2fr;
  display: grid;
  justify-content: center;
}
.content {
  margin: 10px 150px;
  background: white;
}
.inner-content {
  display: flex;
  flex-direction: column;
  color: black;

  grid-gap: 20px;
}
.inner-content h1 {
  display: flex;
  justify-content: start;
}
.text {
  grid-area: text;
}
.content-header {
  grid-area: header;
}
</style>