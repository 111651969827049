import {watch,reactive, ref} from 'vue'

export const useField = (field) =>{
    const valid = ref(true)
    const value = ref(field.value)
    const errors = reactive({})
    const touched = ref(false)
    const reassign = () =>{
        valid.value = true
        Object.keys(field.validators??{}).map(name =>{
            const isValid = field.validators[name](value.value)
            errors[name] = !isValid
            if(!isValid){
                valid.value = false
            }
        })
    }
    
 watch(value,reassign)
 reassign(field.value)
    return {valid,value,errors,touched,blur: () => touched.value = true }
}