<template>
  <a @click.prevent="toggleHeaderPopup" href="#" class="toggle_button">
    <span
      class="bar"
      :style="'background-color:' + color + '!important' + ';'"
    ></span>
    <span
      class="bar"
      :style="'background-color:' + color + '!important' + ';'"
    ></span>
    <span
      class="bar"
      :style="'background-color:' + color + '!important' + ';'"
    ></span>
  </a>

  <transition name="fade">
    <Popup v-if="popupTriggers" v-on:onChangeToggle="togglePopup"></Popup>
  </transition>

  <nav class="headline__nav" ref="nav">
    <a
      @click.prevent="togglePopup"
      href="#"
      :style="'color:' + color + ';'"
      >Стать клиентом</a
    >
    <router-link :to="{ name: 'about' }" :style="'color:' + color + ';'"
      >Кто мы</router-link
    >
    <router-link :to="{ name: 'services' }" :style="'color:' + color + ';'"
      >Услуги</router-link
    >
    <router-link :to="{ name: 'works' }" :style="'color:' + color + ';'"
      >Портфолио</router-link
    >
    <router-link :to="{ name: 'contacts' }" :style="'color:' + color + ';'"
      >Контакты</router-link
    >
    <a href="tel:+77058094969" class="nav__phone" :style="'color:' + color + ';'"
      >+7 705 809 4969</a
    >
  </nav>

  <transition name="modal">
    <HeaderPopup
      v-if="onClickQueryBtn"
      v-on:onToggle="toggleHeaderPopup"
      v-on:onChangeToggle="togglePopup"
    ></HeaderPopup>
  </transition>
</template>

<script>
import { ref } from "vue";
import Popup from "../components/PopupComponent";
import HeaderPopup from "../components/HeaderPopup";

export default {
  name: "NavComponent",
  components: {
    Popup,
    HeaderPopup,
  },

  // data() {
  //   return {
  //     color: 'white'
  //   }
  // },
  setup() {
    const popupTriggers = ref(false);
    const onClickQueryBtn = ref(false);
    const toggleHeaderPopup = () => {
      onClickQueryBtn.value = !onClickQueryBtn.value;
    };
    const togglePopup = () => {
      popupTriggers.value = !popupTriggers.value;
    };

    return {
      popupTriggers,
      togglePopup,
      toggleHeaderPopup,
      onClickQueryBtn,
    };
  },
  methods: {
    toggleMenu() {
      const btn = this.$refs.btn.classList;
      const nav = this.$refs.nav.classList;
      nav.contains("active") ? nav.remove("active") : nav.add("active");
      btn.contains("active") ? btn.remove("active") : btn.add("active");
    },
  },

  props: {
    color: {
      type: String,
      default: "#fff",
    },
  },
};
</script>

<style>
.modal-enter-from,
.modal-leave-to {
  transform: translateX(100px);
  opacity: 0;
}
.modal-enter-active,
.modal-leave-active {
  transition: all 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  transform: translateY(-60px);
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}

.toggle_button {
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
}
.toggle_button .bar {
  height: 3px;
  width: 100%;

  border-radius: 10px;
}
.headline__nav {
  display: flex;
  flex-direction: row;
}
.headline__nav a {
  display: flex;
  /* color: #fff; */
  /* font-size: 18px; */
  font-weight: lighter;
  text-decoration: none;
  margin-right: 50px;
  height: 1.4em;
}
.headline__nav a:hover {
  text-decoration: underline;
}

@media screen and (min-width: 769px) and (max-width: 945px) {
  .headline__nav {
    display: none;
  }
  .toggle_button {
    display: flex;
    margin: 50px;
  }
}
@media screen and (max-width: 576px) {
  .headline__nav {
    display: none;
  }
  .toggle_button {
    display: flex;
    margin: 25px;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .headline__nav {
    display: none;
  }
  .toggle_button {
    display: flex;
    margin: 25px;
  }
}
</style>
