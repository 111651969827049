<template>
  <header class="header-home">
    <div class="wrapper">
      <div class="header-home__headline">
        <a class="headline__logo" href="/">
          <img src="/images/logo_white.svg" alt="Logo CodeTau" />
        </a>
        <NavComponent></NavComponent>
      </div>
      <!--<Popup v-if="popupTriggers" v-on:onChangeToggle="togglePopup">Here will be form</Popup> -->
      <div class="header__banner">
        <p class="banner__text">
          Успешная работа с <a href="/highload">High Load</a>,
          <a href="/bigdata">Big Data</a> проектами, разного рода
          <a href="/integration">интеграциями</a>,
          <a href="/platform">кроссплатформенными</a> приложениями, позволяет
          нам браться за разработку любой сложности
        </p>
        <div class="banner__image">
          <img src="/images/animation/Group120.png" alt="animation" />
        </div>
        <div class="banner__socials">
          <div class="socials__inner">
            <a href="https://youtube.com/channel/UCDX42MoqVOICyqL-D0eWqVw">
              <img src="/images/i/youtube.png" alt="social" />
            </a>
            <a href="#">
              <img src="/images/i/linkedin.png" alt="social" />
            </a>
            <a href="https://instagram.com/codetau">
              <img src="/images/i/instagram.png" alt="social" />
            </a>
          </div>
        </div>
      </div>
      <div class="header__mission">
        <h4>Миссия CodeTau:</h4>
        <p>
          Создать продукт, который будет полезен для человечества, результатом
          которого будет развитие новой сферы занятости для людей.
        </p>
      </div>
    </div>
  </header>
  <!-- <div class="portfolio">
    <img class="portfolio__bg" src="/images/bg_index_2.png" alt="portfolio" />
    <div class="portfolio__desktop">
      <img src="/images/portfolio/desktop.png" alt="desktop" />
    </div>
    <div class="portfolio__phone">
      <img src="/images/portfolio/eqonaq_app.jpg" alt="app" />
    </div>
    <div class="portfolio__tablet">
      <img src="/images/portfolio/planwet.png" alt="tablet" />
    </div>
    <a href="/works" class="portfolio__link">Все проекты</a>
  </div> -->
  <div class="dearcustomer">
    <div class="wrapper">
      <div class="dearcustomer__inner">
        <div class="dearcustomer__info">
          <h3 class="dearcustomer__title">Дорогой партнер,</h3>
          <p class="dearcustomer__text">
            Мы рады что имеем возможность сотрудничать с Вами и создавать
            сложные и интересные проекты. Мы работаем, чтобы Вы не замечали
            обременительных мелочей, и рады, если нам это удается! Мы готовы
            решать сложные задачи, покорять новые вершины, достигать намеченные
            цели. Мы бла­года­рим Вас за воз­можность сот­рудни­чества !
          </p>
          <p class="dearcustomer__withrespect">С уважением, команда CodeTau</p>
        </div>
        <div class="dearcustomer__signatures">
          <img src="/images/signatures.svg" alt="signatures" />
        </div>
      </div>
      <a href="/files/portfolio.pdf" class="dearcustomer__link" @click="getReq"
        >Скачать портфолио</a
      >
    </div>
  </div>
  <FooterComponent></FooterComponent>
</template>

<script>
import NavComponent from "../components/NavComponent";
import FooterComponent from "../components/FooterComponent";
import instance from "../api/instance";

export default {
  name: "Home",
  components: {
    NavComponent,
    FooterComponent,
  },
  setup() {
    /*const popupTriggers = ref(false)
     const togglePopup = () => {
      popupTriggers.value = !popupTriggers.value
    }*/
    async function getReq() {
      const res = await instance.get("/portfolio/download");
      console.log(res);
    }
    return {
      getReq,
    };
  },
};
</script>

<style scoped>
.header-home {
  background: #1a162c;
  padding: 25px 0;
  color: #fff;
  min-height: 10vh;
}
.header-home__headline {
  display: flex;
  justify-content: space-between;
}
.headline__logo {
  display: block;
}

/* .nav__phone {

  } */
.header-home .header__banner {
  display: grid;

  grid-template-columns: 3fr 2fr;
  grid-gap: 20px;
  grid-auto-rows: minmax(130px, auto);
  grid-template: none;
}
.banner__text {
  font-size: 30px;
  grid-column: 1;
  grid-row: 2;
}

.banner__text a {
  color: #00c5c9;
  text-decoration: none;
}
.banner__text a:hover {
  text-decoration: underline;
}
.banner__image {
  display: grid;
  justify-content: center;
  align-items: center;

  grid-column: 2;
  grid-row-start: 1;
  grid-row-end: 4;
}
.banner__image img {
  max-height: 100%;
}

.socials__inner {
  display: flex;
}
.banner__socials {
  display: grid;
  grid-column: 1;
  grid-row: 3;
  align-items: end;
}
.banner__socials a {
  display: block;
  margin-right: 40px;
}
.banner__socials a:hover {
  opacity: 0.8;
}
.header__mission {
  display: grid;
  grid-template-columns: 1fr 3fr;
  margin: 40px 0;
}
.header__mission p {
  text-align: start;
}
.header__mission h4 {
  font-size: 24px;
  font-weight: normal;
}
.header__mission {
  font-weight: lighter;
}
.portfolio {
  position: relative;
  overflow: hidden;
  /* height: 100vh; */
}
.portfolio__bg {
  position: relative;
  width: 100%;
  z-index: 10;
  vertical-align: bottom;
}
.portfolio__desktop {
  /* overflow: hidden; */

  position: absolute;
  top: 5%;
  left: 25%;
  right: 21%;
  bottom: 63%;
  transform: perspective(60em) rotateX(-25deg);
  z-index: 1;
}
.portfolio__desktop img {
  max-width: 100%;
  /* position: fixed; Zhenis check please my this lifehack */
}
.portfolio__phone {
  position: absolute;
  top: 43.5%;
  left: 9.8%;
  right: 80.7%;
  bottom: 25.8%;
  /* height: 500px; */
  border-radius: 60px;
  /* transform: perspective(50em) rotateX(-10deg); */
  z-index: 1;
  overflow: hidden;
}
.portfolio__phone img {
  max-width: 100%;
  /* position: fixed; Zhenis check please my this lifehack */
}
.portfolio__tablet {
  position: absolute;
  top: 36.9%;
  left: 39.6%;
  right: 32.8%;
  bottom: 3%;
  /* height: 500px; */
  border-radius: 5.4%;
  transform: perspective(65em) rotateX(-6deg);
  z-index: 2;
  overflow: hidden;
}
.portfolio__tablet img {
  max-width: 100%;
  /* position: fixed; Zhenis check please my this lifehack */
}
.portfolio__link {
  display: block;
  position: absolute;
  top: 84%;
  left: 7%;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  background: #3d59ea;
  padding: 18px 60px;
  border-radius: 10px;
  z-index: 12;
}
.dearcustomer {
  padding: 70px 0;
}

.dearcustomer__inner {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 2rem;
}
.dearcustomer__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.dearcustomer__signatures {
  display: flex;
  align-items: center;
}
.dearcustomer__title {
  font-size: 30px;
  margin-bottom: 40px;
}
.dearcustomer__text {
  font-weight: lighter;
  margin-bottom: 40px;
}
.dearcustomer__withrespect {
  font-style: italic;
  margin-bottom: 40px;
}
.dearcustomer__link {
  display: inline-block;
  font-weight: bold;
  /* color: #fff; */
  text-decoration: none;
  color: #3d59ea;
  border: 1px solid #3d59ea;
  padding: 18px 60px;
  border-radius: 10px;
}
@media screen and (min-width: 1001px) and (max-width: 1070px) {
  .portfolio__tablet {
    position: absolute;
    top: 37.4%;
    left: 40%;
    right: 33%;
    bottom: 3%;
    width: 282px;
    /* height: 500px; */
    border-radius: 6.4%;
    transform: perspective(35em) rotateX(-10deg);
    z-index: 2;
    overflow: hidden;
  }
}
@media screen and (min-width: 769px) and (max-width: 1000px) {
  .wrapper .dearcustomer__inner {
    grid-template-columns: auto;
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .dearcustomer__link {
    display: block;
    font-weight: bold;
    /* color: #fff; */
    text-decoration: none;
    color: #3d59ea;
    border: 1px solid #3d59ea;
    padding: 18px 60px;
    border-radius: 10px;
    text-align: center;
  }
  .header__banner {
    display: block;
  }
  .header-home .header__banner {
    display: block !important;
    padding-top: 50px;
    padding-bottom: 10px;
  }
  .banner__text {
    font-size: 25px;
    margin-bottom: 15px;
  }
  .banner__socials {
    justify-content: center;
    margin-top: 30px;
  }
  .dearcustomer {
    padding: 20px 0;
  }
  .wrapper .dearcustomer__inner {
    grid-template-columns: auto;
    margin-bottom: 20px;
  }
  .header__mission {
    margin-right: 30px;
  }
  .portfolio__link {
    display: block;
    position: absolute;
    top: 84%;
    left: 7%;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
    background: #3d59ea;
    padding: 18px 60px;
    border-radius: 10px;
    z-index: 12;
  }
}
@media screen and (max-width: 576px) {
  .banner__text {
    font-size: 18px;
    /* font-weight: 500; */
  }
  .banner__image {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .socials__inner {
    display: flex;
    margin: auto;
  }
  .header__mission {
    display: block;
    grid-template-columns: 1fr 3fr;
    margin: 40px 0;
  }
  .header__mission h4 {
    margin-bottom: 10px;
  }
  .wrapper .dearcustomer__inner {
    grid-template-columns: auto;
    margin-bottom: 20px;
  }
  .portfolio__link {
    display: block;
    position: absolute;
    top: 74%;
    left: 35%;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
    background: #3d59ea;
    padding: 12px 30px;
    border-radius: 10px;
    z-index: 12;
  }
  .header-home .header__banner {
    display: block;
    padding-top: 10px;

    grid-gap: 20px;
  }
  .header-home {
    background: #1a162c;
    padding: 25px 0;
    color: #fff;
    min-height: 0;
  }
}
@media screen and (min-width: 1350px) and (max-width: 1500px) {
  .header__banner {
    padding-top: 90px;
  }
}
</style>
