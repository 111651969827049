import {reactive} from 'vue'
import {useField} from './useField'
export const footerForm = (init = {}) => {
  
    const footer = reactive({})
    for (const [key,value] of Object.entries(init)){
        
        footer[key] = useField(value)
    }

    return footer
}