import { createWebHistory, createRouter } from "vue-router";
import HomePage from '../pages/Home.vue'
import AboutPage from '../pages/About.vue'
import ServicesPage from '../pages/Services.vue'
import WorksPage from '../pages/Works.vue'
import ContactsPage from '../pages/Contacts.vue'
import NotFound from '../pages/NotFound.vue'
import LinksPage from '../pages/LinksPage'
import BigData from '../pages/BigData'
import Platform from '../pages/Platform'
import Integration from '../pages/Integration'

const routes = [
    {
        path: '/',
        name: 'index',
        component: HomePage,
        meta: { title: `Digital-агентство CodeTau` }
    },
    {
        path: '/about',
        name: 'about',
        component: AboutPage,
        meta: { title: `О команде | CodeTau` }
    },
    {
        path: '/services',
        name: 'services',
        component: ServicesPage,
        meta: { title: `Услуги | CodeTau` }
    },
    {
        path: '/works',
        name: 'works',
        component: WorksPage,
        meta: { title: `Портфолио | CodeTau` }
    },
    {
        path: '/contacts',
        name: 'contacts',
        component: ContactsPage,
        meta: { title: `Контакты | CodeTau` }
    },
    {
        path: "/:catchAll(.*)",
        component: NotFound,
        meta: { title: `Страница не найдено` }
    },
    {
        path: '/highload',
        name: 'projects',
        component: LinksPage,
        meta: { title: `High Load Projects | CodeTau` }
    },
    {
        path: '/bigdata',
        name: 'bigdata',
        component: BigData,
        meta: { title: `BigData | CodeTau` }
    },
    {
        path: '/platform',
        name: 'platform',
        component: Platform,
        meta: { title: `Platform | CodeTau` }
    },
    {
        path: '/integration',
        name: 'integration',
        component: Integration,
        meta: { title: `Integration | CodeTau` }
    },
];

const router = createRouter({
    // linkActiveClass: 'is-active',
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    next()
})
console.log(router)

export default router;