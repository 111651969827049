<template>
  <div class="vacancy">
    <div class="wrapper">
      <h3>
        Хотите работать у нас?<br />
        Заполните заявку и мы свяжемся
      </h3>
      <div class="vacancy__inner">
        <form
          action="/"
          method="GET"
          class="vacancy__feedback"
          @submit.prevent="workReq"
        >
          <input
            type="text"
            name="name"
            placeholder="Ваше имя"
            v-model="info.name"
            @change="onChangeInput"
          />
          <input
            type="text"
            name="phone"
            placeholder="Номер телефона"
            v-model="info.phone"
          />
          <input
            type="text"
            name="position"
            placeholder="Навыки"
            v-model="info.skill"
          />
          <div class="vacancy__selects">
            <label>
              <input
                value="backend"
                type="radio"
                name="position_example"
                v-model="info.position"
              />
              <span class="vacancy__control"></span>
              Backend
            </label>
            <label>
              <input
                value="devops"
                type="radio"
                name="position_example"
                v-model="info.position"
              />
              <span class="vacancy__control"></span>
              Dev Ops Engineer
            </label>
            <label>
              <input
                value="android"
                type="radio"
                name="position_example"
                v-model="info.position"
              />
              <span class="vacancy__control"></span>
              Android Developer
            </label>
            <label>
              <input
                value="frontend"
                type="radio"
                name="position_example"
                v-model="info.position"
              />
              <span class="vacancy__control"></span>
              Frontend
            </label>
            <label>
              <input
                value="project_manager"
                type="radio"
                name="position_example"
                v-model="info.position"
              />
              <span class="vacancy__control"></span>
              Project Manager
            </label>
            <label>
              <input
                value="ios"
                type="radio"
                name="position_example"
                v-model="info.position"
              />
              <span class="vacancy__control"></span>
              iOS Developer
            </label>
          </div>
          <button type="submit" :disabled="isFormActive">Подать заявку</button>
        </form>
        <div class="vacancy__image">
          <img src="/images/animation/mailbox.png" alt="mailbox" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import instance from "../api/instance";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
import { reactive, ref } from "vue";
export default {
  name: "VacancyComponent",
  setup() {
    let info = reactive({
      name: null,
      phone: null,
      skill: null,
      position: null,
    });
    let isFormActive = ref(false);
    async function workReq() {
      const res = await instance.post("/request/work", {
        name: info.name,
        phone: info.phone,
        skill: info.skill,
        position: info.position,
      });
      isFormActive.value = true;
      createToast(res.data.message, { type: "success" });
    }
    function onChangeInput() {
      if (info.name.length > 2) {
        isFormActive.value = false;
      }
    }
    return {
      workReq,
      onChangeInput,
      info,
      isFormActive,
    };
  },
};
</script>

<style scoped>
.vacancy {
  padding: 70px 0;
}
.vacancy__inner {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 70px;
}
.vacancy h3 {
  font-size: 30px;
  margin-bottom: 70px;
}
.vacancy form input[type="text"] {
  display: block;
  background: none;
  border-bottom: 1px solid #e5e5e5;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 0;
  /* margin-bottom: 30px; */
}
.vacancy form input[type="text"]::placeholder {
  color: #e5e5e5;
}
.vacancy form input[type="text"]:nth-child(3) {
  margin-bottom: 30px;
}
.vacancy form button {
  display: inline-block;
  background: none;
  font-weight: bold;
  text-decoration: none;
  color: #fff;
  background: #3d59ea;
  padding: 18px 60px;
  border-radius: 10px;
}
.vacancy__selects {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  margin-bottom: 50px;
}
.vacancy__selects label {
  display: flex;
  color: #6d6c7b;
  cursor: pointer;
}
.vacancy input[type="radio"] {
  opacity: 0;
  width: 0;
  height: 0;
}
.vacancy__control {
  display: block;
  position: relative;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 0.1em solid #3d59ea;
  margin-right: 10px;
  /* transform: translateY(-0.05em); */
}
.vacancy input[type="radio"]:checked + .vacancy__control::before {
  position: absolute;
  content: "";
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  left: calc(50% - 0.8em / 2);
  top: calc(50% - 0.8em / 2);
  background: #3d59ea;
}
@media screen and (max-width: 578px) {
  .vacancy__selects {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin-bottom: 50px;
  }
  .vacancy h3 {
    font-size: 25px;
    margin-bottom: 20px;
  }
  .vacancy {
    padding: 30px 0;
  }
  .vacancy__inner {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 70px;
  }
  button {
    width: 100%;
  }
  .vacancy__image {
    display: none;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .vacancy__inner {
    display: block;
  }
  .vacancy__image {
    display: none;
  }
}
@media screen and (min-width: 769px) and (max-width: 945px) {
  .vacancy {
    margin-left: 30px;
  }
}
</style>
