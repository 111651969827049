<template>
  <nav class="headline__nav" ref="nav">
    <span class="closeButton" @click="$emit('onToggle')">+</span>
    <div class="inner_menu">
      <router-link
        @click.prevent ="$emit('onChangeToggle')"
        to='/'
        :style="'color:' + color + ';'"
        >Стать клиентом</router-link>
      <router-link :to="{name:'about'}" :style="'color:' + color + ';'">Кто мы</router-link>
      <router-link :to="{name:'services'}" :style="'color:' + color + ';'">Услуги</router-link>
      <router-link :to="{name:'works'}" :style="'color:' + color + ';'">Портфолио</router-link>
      <router-link :to="{name:'contacts'}" :style="'color:' + color + ';'">Контакты</router-link>
      <a href="tel:+77058094969" class="nav__phone" :style="'color:' + color + ';'">+7 705 809 4969</a>
    </div>
  </nav>
</template>

<script>
export default {

};
</script>

<style scoped>
.headline__nav {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  z-index: 98;
  flex-direction: column;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.inner_menu {
  display: block;

  background: rgb(255, 255, 255);
  padding-bottom: 50%;
  padding-top: 25%;
  padding-right: 5%;
  padding-left: 5%;
}
.closeButton {
  position: fixed;
  right: 0;
  top: 0;
  margin: 25px;
  color: black;
  transform: rotate(45deg);
  font-size: 50px;
}
.headline__nav a:hover {
  text-decoration: underline;
}
.headline__nav{
     color: black !important;
  }
    
@media screen and (min-width: 769px) and (max-width: 945px) {
  .nav__phone{
    margin-left: 20px
  }
  .headline__nav {
    position: fixed;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    z-index: 98;
    flex-direction: column;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  .closeButton {
  position: fixed;
  right: 0;
  top: 0;
 
  color: black;
  transform: rotate(45deg);
  font-size: 40px;
}
  .headline__nav a {
    padding: 0.5rem 1rem;
    border-radius: 10px;
    margin-bottom: 25px;
    margin-top: 10px;
    width: 100%;
    color: black;
  }
  .inner_menu {
    display: flex;
    flex-direction: column;
    background: rgb(255, 255, 255);
    padding-bottom: 25%;
    padding-top: 25%;
    padding-right: 5%;
    padding-left: 5%;
  }

}

@media screen and (max-width: 576px){
  .nav__phone{
    margin-left: 20px
  }
  .headline__nav {
    position: fixed;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    z-index: 98;
    flex-direction: column;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  .closeButton {
  position: fixed;
  right: 0;
  top: 0;
  
  color: black;
  font-size: 40px;
  transform: rotate(45deg);
  
}
   .headline__nav a {
    padding: 0.5rem 1rem;
    border-radius: 10px;
    margin-bottom: 25px;
    margin-top: 10px;
    width: 100%;
    color: black;
  }
  .inner_menu {
    display: block;
    background: rgb(255, 255, 255);
    padding-bottom: 75%;
    padding-top: 50%;
    padding-right: 5%;
    padding-left: 5%;
  }
  
}
@media screen and (min-width: 577px) and (max-width: 768px){
  
  .headline__nav {
    position: fixed;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    z-index: 98;
    flex-direction: column;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    transition: 300ms ease all;
    
  }
  .closeButton {
  position: fixed;
  right: 0;
  top: 0;
  margin: 25px;
  color: black;
  
  transform: rotate(45deg);
  
}
   .headline__nav a{
    padding: 0.5rem 1rem;
   
    border-radius: 10px;
    margin-bottom: 25px;
    margin-top: 10px;
    width: 100%;
    color: black;
  }
  .inner_menu {
    display: flex;
    flex-direction: column;
    
 
    background: rgb(255, 255, 255);
    padding-bottom: 50%;
    padding-top: 50%;
    padding-right: 5%;
    padding-left: 5%;
  }
  .nav__phone{
    margin-left: 20px
  }
}
@media (orientation: landscape) {
 
}
</style>