<template>
  <div class="popup">
    <div class="inner-popup">
      <form
        action="/"
        method="POST"
        class="popup__feedback"
        @submit.prevent="sendPopupReq"
      >
        <div class="form-control">
          <input
            name="name"
            placeholder="Ваше имя"
            v-model="popupForm.name"
            @change="onChange"
          />
        </div>
        <!-- v-model='name' -->
        <div class="form-control">
          <input
            name="phone"
            placeholder="Номер телефона"
            v-model="popupForm.phone"
          />
        </div>
        <!--  v-model='phone' -->
        <button type="submit" class="sendReq" :disabled="isFormActive">
          Отправить заявку
        </button>
      </form>
      <button class="btn-close-popup" @click="$emit('onChangeToggle')">
        Закрыть
      </button>
    </div>
  </div>
</template>

<script>
import instance from "../api/instance";
import { reactive, ref } from "vue";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

export default {
  setup() {
    let isFormActive = ref(false);
    const popupForm = reactive({
      name: null,
      phone: null,
    });

    async function sendPopupReq() {
      const res = await instance.post("/request/client", {
        name: popupForm.name,
        phone: popupForm.phone,
      });
      isFormActive.value = true;
      createToast(res.data.message, { type: "success" });
    }
    function onChange() {
      if (popupForm.name.length > 2) {
        isFormActive.value = false;
      }
    }
    return {
      isFormActive,
      onChange,
      popupForm,
      sendPopupReq,
    };
  },
};
</script>

<style scoped>
.sendReq {
  color: white;
}
input {
  border-bottom: 1px solid #484556;
}
.popup {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inner-popup {
  background: #fff;
    padding: 50px 50px 24px 50px;
    border-radius: 5%;
    display: flex;
    flex-direction: column;
    height: 274px;
    justify-content: space-around;
}
.popup__feedback button {
  display: inline-block;
  background: none;
  font-weight: bold;
  text-decoration: none;
  background: #3d59ea;
  border: 1px solid #fff;
  padding: 18px 60px;
  border-radius: 10px;
}
.form-control input {
  color: black;
  width: 100%;
  margin-bottom: 40px;
}
.btn-close-popup {
 
  background: none;
  font-weight: bold;
  text-decoration: none;
 

}
</style>